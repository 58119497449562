import React from 'react'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { spacing } from 'ui-primitives/src/utils/spacing'

import { colors } from 'bl-common/src/constants/colors'
import * as styles from 'bl-common/src/units/Form/styles'

import { Amex } from './cards/Amex'
import { DefaultCard } from './cards/DefaultCard'
import { Diners } from './cards/Diners'
import { JCB } from './cards/JCB'
import { Mastercard } from './cards/Mastercard'
import { Visa } from './cards/Visa'
import { CardTypes } from './types'

const cardIcons = {
  [CardTypes.VISA]: Visa,
  [CardTypes.MASTERCARD]: Mastercard,
  [CardTypes.AMERICAN_EXPRESS]: Amex,
  [CardTypes.DINERS]: Diners,
  [CardTypes.JCB]: JCB,
}

const cardIconWidth = 30

const StyledCardIcon = styled.div({
  position: 'absolute',
  left: spacing[1],
  top: '50%',
  transform: 'translateY(-50%)',
  width: cardIconWidth,
})

const SelectedCard = ({
  identifiedCard,
  defaultCardColor,
}: {
  identifiedCard?: CardTypes
  defaultCardColor?: string
}) => {
  const CardIcon = identifiedCard && cardIcons[identifiedCard]

  return (
    <StyledCardIcon>
      {CardIcon ? (
        <CardIcon
          color={
            identifiedCard === CardTypes.VISA ? defaultCardColor : undefined
          }
        />
      ) : (
        <DefaultCard color={defaultCardColor || colors.formGrey} />
      )}
    </StyledCardIcon>
  )
}

const StyledInputContainer = styled.div`
  position: relative;
  height: 56px;
  display: flex;
  flex-direction: column;
`

type FieldType = 'cvv-number' | 'card-number'

type StyledInputProps = {
  fieldType: FieldType
  hasError?: boolean
  isFocused?: boolean
  themeStyle?: {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    hoverBorderColor?: string
    borderRadius?: number
  }
}

const StyledInput = styled.div<StyledInputProps>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  border: 1px solid
    ${props => props?.themeStyle?.borderColor ?? colors.formGrey};
  color: ${props => props?.themeStyle?.textColor ?? colors.formDark};
  font-size: 16px;
  height: 56px;
  outline: none;
  padding: ${spacing[0.5]} ${spacing[1]};
  padding-left: ${({ fieldType }) =>
    fieldType === 'card-number'
      ? `calc(1 * ${spacing[1.5]} + ${cardIconWidth}px)`
      : spacing[1]};
  width: 100%;
  appearance: none;
  border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
  transition: box-shadow 150ms, border 150ms;

  :hover {
    border: 1px solid
      ${props => props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
  }

  ${({ isFocused, themeStyle }) =>
    isFocused &&
    css`
      border: 1px solid ${themeStyle?.hoverBorderColor ?? colors.fountainBlue};
      box-shadow: 0 0 12px
        ${rgba(themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
      background: ${themeStyle?.backgroundColor ?? colors.white};
    `}

  ${({ hasError, isFocused, themeStyle }) =>
    hasError &&
    !isFocused &&
    css`
      background: ${rgba(colors.errorRed, 0.15)};
      border: 1px solid ${colors.errorRed};

      &:hover {
        box-shadow: 0 0 12px
          ${rgba(themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
      }
    `}
`

type FieldProps = {
  fieldType: FieldType
  label: string
  paymentMethod?: CardTypes
  error?: string | null
  isFocused?: boolean
  themeStyle?: {
    labelColor?: string
    defaultCardColor?: string
    input?: StyledInputProps['themeStyle']
  }
}

export const Field = ({
  fieldType,
  label,
  paymentMethod,
  error,
  isFocused,
  themeStyle,
}: FieldProps) => {
  return (
    <>
      <styles.TopWrapper>
        <styles.Label htmlFor={fieldType} color={themeStyle?.labelColor}>
          {label}*
        </styles.Label>
        <styles.ErrorMessage>{error}</styles.ErrorMessage>
      </styles.TopWrapper>
      <StyledInputContainer>
        {fieldType === 'card-number' && (
          <SelectedCard
            identifiedCard={paymentMethod}
            defaultCardColor={themeStyle?.defaultCardColor}
          />
        )}
        <StyledInput
          id={fieldType}
          fieldType={fieldType}
          hasError={Boolean(error)}
          isFocused={isFocused}
          themeStyle={themeStyle?.input}
        />
      </StyledInputContainer>
    </>
  )
}
