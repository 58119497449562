import * as React from 'react'
export const Diners = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    viewBox="62.5 1.25 136.32 87.38"
  >
    <defs>
      <style>{'.b{fill:#0079be;}.b,.c{stroke-width:0px;}.c{fill:#fff;}'}</style>
    </defs>
    <path
      className="b"
      d="M69.49,1.25h122.34c3.86,0,6.99,3.13,6.99,6.99v73.4c0,3.86-3.13,6.99-6.99,6.99h-122.34c-3.86,0-6.99-3.13-6.99-6.99V8.25c0-3.86,3.13-6.99,6.99-6.99Z"
    />
    <path
      className="c"
      d="M167.35,45.2c0-17.38-14.5-29.38-30.39-29.38h-13.67c-16.08,0-29.31,12.01-29.31,29.38,0,15.89,13.24,28.95,29.31,28.87h13.67c15.89.08,30.39-12.98,30.39-28.87Z"
    />
    <path
      className="b"
      d="M123.37,18.28c-14.69,0-26.6,11.94-26.6,26.67,0,14.73,11.91,26.66,26.6,26.66,14.7,0,26.61-11.94,26.61-26.66,0-14.73-11.91-26.66-26.61-26.67h0Z"
    />
    <path
      className="c"
      d="M106.56,44.88c.01-7.2,4.5-13.33,10.83-15.77v31.54c-6.33-2.44-10.81-8.57-10.83-15.77ZM129.45,60.66v-31.55c6.33,2.43,10.82,8.57,10.83,15.77-.01,7.2-4.5,13.34-10.83,15.78h0Z"
    />
  </svg>
)
