import { ReactNode } from 'react'
import kebabCase from 'lodash/kebabCase'
import styled, {
  css,
  CSSProperties,
  FlattenSimpleInterpolation,
} from 'styled-components'
// styled-components-breakpoint v.2.0.2 does not have types and v3 preview has types but development was dropped 5 years ago
// We should use // @ts-expect-error but it causes an error on apps
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Apps trigger an error on @ts-expect-error because they are not as strict as ui-primitives
import { map } from 'styled-components-breakpoint'

import { breakpoints } from './utils/breakpoints'
import { spacing } from './utils/spacing'

type Breakpoint = keyof typeof breakpoints
export type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

type StyleProp<T extends keyof CSSProperties> =
  | CSSProperties[T]
  | BreakpointMap<CSSProperties[T]>

interface BoxProps {
  children?: ReactNode
  position?: StyleProp<'position'>
  bottom?: StyleProp<'bottom'>
  left?: StyleProp<'left'>
  right?: StyleProp<'right'>
  top?: StyleProp<'top'>
  zIndex?: StyleProp<'zIndex'>
  maxWidth?: StyleProp<'maxWidth'>
  margin?: StyleProp<'margin'>
  marginTop?: StyleProp<'marginTop'>
  marginRight?: StyleProp<'marginRight'>
  marginBottom?: StyleProp<'marginBottom'>
  marginLeft?: StyleProp<'marginLeft'>
  padding?: StyleProp<'padding'>
  paddingTop?: StyleProp<'paddingTop'>
  paddingRight?: StyleProp<'paddingRight'>
  paddingBottom?: StyleProp<'paddingBottom'>
  paddingLeft?: StyleProp<'paddingLeft'>
  textAlign?: StyleProp<'textAlign'>
  $height?: StyleProp<'height'>
  $width?: StyleProp<'width'>
  display?: StyleProp<'display'>
  opacity?: StyleProp<'opacity'>
  gap?: StyleProp<'gap'>
  flexDirection?: StyleProp<'flexDirection'>
  flexBasis?: StyleProp<'flexBasis'>
  flexWrap?: StyleProp<'flexWrap'>
  justifyContent?: StyleProp<'justifyContent'>
  alignItems?: StyleProp<'alignItems'>
  boxShadow?: StyleProp<'boxShadow'>
  background?: StyleProp<'background'>
  color?: StyleProp<'color'>
}

const cssProps: Record<keyof Omit<BoxProps, 'children'>, keyof CSSProperties> =
  {
    position: 'position',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    top: 'top',
    zIndex: 'zIndex',
    maxWidth: 'maxWidth',
    margin: 'margin',
    marginTop: 'marginTop',
    marginRight: 'marginRight',
    marginBottom: 'marginBottom',
    marginLeft: 'marginLeft',
    padding: 'padding',
    paddingTop: 'paddingTop',
    paddingRight: 'paddingRight',
    paddingBottom: 'paddingBottom',
    paddingLeft: 'paddingLeft',
    textAlign: 'textAlign',
    $width: 'width',
    $height: 'height',
    display: 'display',
    opacity: 'opacity',
    gap: 'gap',
    flexDirection: 'flexDirection',
    flexBasis: 'flexBasis',
    flexWrap: 'flexWrap',
    justifyContent: 'justifyContent',
    alignItems: 'alignItems',
    boxShadow: 'boxShadow',
    background: 'background',
    color: 'color',
  }

const spacingProperties = new Set([
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'gap',
])

const defaultStyles = {
  $width: '100%',
  display: 'flex',
  flexDirection: 'column',
}

function applyStyles(
  props: Omit<BoxProps, 'children'>
): FlattenSimpleInterpolation {
  const combinedStyles = { ...defaultStyles, ...props }
  return (
    Object.keys(combinedStyles) as Array<keyof typeof combinedStyles>
  ).reduce((acc, key) => {
    const prop = combinedStyles[key]
    const cssProperty = cssProps[key]
    if (prop && cssProperty) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const style =
        typeof prop === 'object'
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            map(
              prop,
              (value: number) => css`
                ${kebabCase(cssProperty)}: ${spacingProperties.has(key) &&
                typeof value === 'number' &&
                spacing?.[value]
                  ? spacing[value]
                  : value};
              `
            )
          : css`
              ${kebabCase(cssProperty)}: ${spacingProperties.has(key) &&
              typeof prop === 'number' &&
              spacing?.[prop]
                ? spacing[prop]
                : prop};
            `
      return css`
        ${acc}${style as string}
      `
    }
    return acc
  }, css``)
}

/* The double typing is a typescript react quirk describes here: https://styled-components.com/docs/api#caveat-with-function-components */
export const Box: React.FC<BoxProps> = styled.div<BoxProps>`
  ${({ theme, ...props }) => applyStyles(props)}
`
