import { colors } from 'bl-common/src/constants/colors'

export const scriptSource =
  process.env.NEXT_PUBLIC_PLANETPAY_SECURE_FIELDS_SCRIPT_URL ||
  'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js'

export const initialInputStyle = (color = colors.formDark) => `
  font-size: 16px;
  font-weight: 200;
  border-radius: 0;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  color: ${color};
  `

export const defaultFormStrings = {
  cardNumber: {
    label: 'Card number',
    placeholder: '**** **** **** ****',
    required: 'Card number is required',
    invalid: 'Card number is invalid',
    notSupported: 'Card type is not supported',
  },
  cvv: {
    label: 'CVC/CVV',
    placeholder: '***',
    required: 'CVC/CVV is required',
    invalid: 'CVC/CVV is invalid',
  },
  expiryDate: {
    label: 'Expiration date',
    placeholder: 'MM/YY',
    required: 'Expiration date is required',
    invalid: 'Expiration date is invalid',
  },
  buttonLabel: 'Submit',
}

export const cardNumberContainer = 'card-number'
export const cvvContainer = 'cvv-number'
export const expiryDateId = 'expiryDate'
