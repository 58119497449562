import React from 'react'
import styled, { keyframes } from 'styled-components'

const RotateKeyframes = keyframes`
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(540deg);
    }
`

const DashKeyframes = keyframes`
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
`

const Car = styled.circle`
  stroke: var(--spinner-color-car, #dee9ef);
`

const Circle = styled.circle`
  stroke: var(--spinner-color-circle, #50a7ba);
  stroke-linecap: round;
`

const Svg = styled.svg`
  z-index: 2;
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};

  opacity: 1;
  animation: ${RotateKeyframes} 3s ease-in-out infinite;
  ${Car} {
    animation: ${DashKeyframes} 1s ease-in-out infinite;
  }
`

type SpinnerProps = {
  radius?: number
  strokeWidth?: number
  className?: string
}

/**
 * Spinner component, color is controlled by CSS variable
 * --spinner-color-circle is color of the circle behind the "car"
 * --spinner-color-car is the color of the "car" that moves around the circle
 * @param radius - radius of the spinner
 * @param strokeWidth - width of the track and the cart
 */
export const Spinner = ({
  radius = 20,
  strokeWidth = radius / 4,
  className,
}: SpinnerProps) => {
  const diameter = radius + strokeWidth
  return (
    <Svg
      className={className}
      viewBox={`0 0 ${diameter * 2} ${diameter * 2}`}
      width={radius * 2}
    >
      <Car
        cx={diameter}
        cy={diameter}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
      ></Car>
      <Circle
        cx={diameter}
        cy={diameter}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
      ></Circle>
    </Svg>
  )
}
