import * as React from 'react'
export const Amex = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="a"
    viewBox="61.6 0.62 137.9 88.23"
  >
    <defs>
      <style>{'.b{fill:#0071ce;stroke-width:0px;}'}</style>
    </defs>
    <path
      className="b"
      d="M187.97,79.21l-6.12-6.82-6.35,6.82h-39.3v-31.77h-12.47l15.53-35.53h15.06l5.41,12.24v-12.24h18.82l3.29,9.18,3.29-9.18h14.35v-5.41c0-3.29-2.59-5.88-5.88-5.88h-126.12c-3.29,0-5.88,2.59-5.88,5.88v76.47c0,3.29,2.59,5.88,5.88,5.88h126.12c3.29,0,5.88-2.59,5.88-5.88v-3.76h-11.53Z"
    />
    <polygon
      className="b"
      points="189.62 75.44 199.5 75.44 186.56 61.56 199.5 47.91 189.85 47.91 181.62 56.85 173.62 47.91 163.73 47.91 176.91 61.8 163.73 75.44 173.38 75.44 181.62 66.5 189.62 75.44"
    />
    <polygon
      className="b"
      points="147.97 69.09 147.97 64.85 163.5 64.85 163.5 58.5 147.97 58.5 147.97 54.27 163.73 54.27 163.73 47.91 140.44 47.91 140.44 75.44 163.73 75.44 163.73 69.09 147.97 69.09"
    />
    <polygon
      className="b"
      points="192.21 43.91 199.26 43.91 199.26 16.15 187.97 16.38 181.85 33.56 175.5 16.38 163.73 16.38 163.73 43.91 171.26 43.91 171.26 24.62 178.32 43.91 184.91 43.91 192.21 24.62 192.21 43.91"
    />
    <path
      className="b"
      d="M151.73,16.38h-9.65l-12.24,27.53h8.24l2.35-5.41h12.71l2.35,5.41h8.47l-12.24-27.53ZM143.03,32.15l3.76-8.94,3.76,8.94h-7.53Z"
    />
    <polygon
      className="b"
      points="192.68 60.85 199.5 68.38 199.5 53.32 192.68 60.85"
    />
  </svg>
)
